/***************************************************
  Imports
***************************************************/

import React from "react"
import { useRecoilState } from "recoil"

import { serviceState } from "../state"

import Layout from "../components/Layout"
import Button from "../components/Button"
import PageNumber from "../components/PageNumber"
import IconButton, { ICONS } from "../components/IconButton"

import BannerImg from "../images/home-banner.svg"
import InfoGraphic from "../images/home-info-graphic.svg"

import OurProductD365 from "../images/home-ourproduct-md365bc.png"
import OurProductPowerApps from "../images/home-ourproduct-powerapps.png"
import OurProductPass from "../images/home-ourproduct-paas.png"
import OurProductFoodbank from "../images/home-ourproduct-foodbank.png"
import OurProductCRM from "../images/home-ourproduct-crm.png"

import SimCrestAppGraphic from "../images/home-simcrestapp-graphic.svg"

import IconArrow from "../images/arrow-right.svg"

/***************************************************
  Component
***************************************************/

export default () => {
  const [service, setService] = useRecoilState(serviceState(services[0]))

  return (
    <Layout id="top" headerTheme="light" className="home">
      <section className="section-hero">
        <div className="col-3 span-5">
          <h2>Infinite Possibilities</h2>
          <h1>ERP Software for Small & Mid-sized Businesses</h1>
          <p>
            Simple and customizable Enterprise Resource Planning software to
            help organizations simplify and streamline highly specialized
            business processes.
          </p>
          <Button to="/contactus">Contact Us</Button>
        </div>
      </section>

      <section className="section-hero-illustration">
        <img className="col-8 span-6" src={BannerImg} alt="SimCrest" />
      </section>

      <section className="section-introduction">
        <div className="col-3 span-5">
          <h2>Fully-integrated Business Management Solutions</h2>
          <p>
            Choosing a business management solution is never easy. You need a
            fully-integrated system, want it to fit the way you do business, and
            hope it is tailored to your specific needs. That is why SimCrest
            endeavors to satisfy individual needs with the most customizable
            solution Microsoft Dynamics 365 Business Central.
          </p>
        </div>

        <div className="col-8 span-7">
          <img
            src={InfoGraphic}
            alt="Fully-integrated Business Management Solutions"
          />
        </div>
      </section>

      <section className="section-our-product-header">
        <PageNumber
          id="products"
          className="col-3 span-11"
          number="01"
          text="OUR PRODUCTS"
        />

        <div className="col-4 span-4">
          <div>
            <h2 className="color-white">
              Microsoft Dynamics 365 Business Central
            </h2>
            <p className="color-white">
              Grow beyond the limits of your basic accounting software. Dynamics
              365 Business Central is an all-in-one business management solution
              that’s easy to use and adapt, helping you connect your business
              and make smarter decisions.
            </p>
            <div className="our-product-images-mobile">
              <img src={OurProductD365} alt="" />
            </div>
            <Button to="/microsoft-dynamics-365-business-central" color="teal">
              FIND OUT MORE
            </Button>
          </div>
        </div>

        <div className="col-8 span-6 our-product-images-desktop">
          <img
            src={OurProductD365}
            alt="Microsoft Dynamics 365 Business Central"
          />
        </div>
      </section>

      <section className="section-our-product">
        <div className="col-4 span-4">
          <div className="our-product-images-desktop">
            <img src={OurProductPowerApps} alt="" />
          </div>
          <h3>Microsoft PowerApps</h3>
          <p>
            Grow beyond the limits of your basic accounting software. Dynamics
            365 Business Central is an all-in-one business management solution
            that’s easy to use and adapt, helping you connect your business and
            make smarter decisions.
          </p>
          <div className="our-product-images-mobile">
            <img src={OurProductPowerApps} alt="" />
          </div>
          <Button to="/products/microsoft-powerapps">FIND OUT MORE</Button>
        </div>

        <div className="col-9 span-4">
          <div className="our-product-images-desktop">
            <img src={OurProductPass} alt="" />
          </div>
          <h3>PaaS for Microsoft Dynamics 365 Business Central</h3>
          <p>
            Building apps with PowerApps helps everyone from business analysts
            to professional developers work more efficiently together. Solve
            business problems with intuitive visual tools that don’t require
            code, work faster with a platform that enables data integration and
            distribution, and extend the platform with building blocks for
            professional developers. Easily build and share apps on any device.
          </p>
          <div className="our-product-images-mobile">
            <img src={OurProductPass} alt="" />
          </div>
          <Button to="/products/paas-for-business-central">
            FIND OUT MORE
          </Button>
        </div>

        <div className="col-4 span-4">
          <div className="our-product-images-desktop">
            <img src={OurProductFoodbank} alt="" />
          </div>
          <h3>Food Bank Software - Ceres</h3>
          <p>
            Designed for the food bank industry, Ceres is a premier business
            management solution with full integration to back-office areas.
            Enjoy your way of doing business with this fully integrated system.
          </p>
          <div className="our-product-images-mobile">
            <img src={OurProductFoodbank} alt="" />
          </div>
          <Button to="/products/food-bank-software-ceres">FIND OUT MORE</Button>
        </div>

        <div className="col-9 span-4">
          <div className="our-product-images-desktop">
            <img src={OurProductCRM} alt="" />
          </div>
          <h3>Microsoft Dynamics CRM</h3>
          <p>
            Microsoft Dynamics CRM provides organizations with technologies,
            tools and practices to help companies market to, sell to and service
            all aspects of a customer relationship.
          </p>
          <div className="our-product-images-mobile">
            <img src={OurProductCRM} alt="" />
          </div>
          <div></div>
        </div>

        <div className="col-4 span-6">
          <h3>Partner Products</h3>
          <p>
            Our partners provide various solutions targeting different business
            needs. Below find some of the most requested partner products
          </p>
          <div>
            <Button to="http://www.lanhamassoc.com/e-ship.htm" target="_blank">
              LANHAM E-SHIP
            </Button>
            <Button to="http://www.lanhamassoc.com/edi.htm" target="_blank">
              LANHAM EDI
            </Button>
            <Button to="https://www.continia.com/global/" target="_blank">
              CONTINIA
            </Button>
            <Button to="https://www.dmsiworks.com/" target="_blank">
              INSIGHT WORKS
            </Button>
            <Button to="https://insightsoftware.com/jet/" target="_blank">
              INSIGHT SOFTWARE (JET)
            </Button>
            <Button to="https://www.navpayroll.com/" target="_blank">
              NAV PAYROLL
            </Button>
            <Button to="https://www.netronic.com/" target="_blank">
              NETRONIC SCHEDULER
            </Button>
          </div>
        </div>
      </section>

      <section className="section-industries">
        <PageNumber
          id="industries"
          className="col-3 span-11"
          number="02"
          text="INDUSTRIES"
        />

        <h2 className="col-4 span-4 color-white">
          Specific Industry Solutions
        </h2>

        <p className="col-8 span-5 color-white">
          SimCrest has over 25 years of experience in working with diverse
          industries, such as manufacturing, distribution, professional service,
          food bank, real estate, oil and gas, credit union, high tech,
          industrial components, specialty retail, hospitality, etc. We are
          equipped with a wealth of knowledge to solve specific industry
          challenges. If you don’t see your industry, ask us about it.
        </p>

        <div className="col-4 span-9">
          <IconButton
            className="light"
            to="/industries/manufacturing"
            icon={ICONS.manufacturing}
            text="Manufacturing"
          />
          <IconButton
            className="light"
            to="/industries/distribution"
            icon={ICONS.distribution}
            text="Distribution"
          />
          <IconButton
            className="light"
            to="/industries/professional-service"
            icon={ICONS.professionalService}
            text="Professional Service"
          />
          <IconButton
            className="light"
            to="/industries/food-banks"
            icon={ICONS.foodBanks}
            text="Food Banks"
          />
          <IconButton
            className="light"
            to="/industries/other-industries"
            icon={ICONS.otherIndustries}
            text="Other Industries"
          />
        </div>
      </section>

      <section className="section-simcrest-apps">
        <PageNumber
          id="simcrest-apps"
          className="col-3 span-11 dark"
          number="03"
          text="SIMCREST APPS"
        />
        <h2 className="col-4 span-4">Get Productive Fast!</h2>
        <div className="col-8 span-5">
          <p>
            SimCrest has built Apps for Dynamics 365 Business Central that will
            make users more productive and save them time.
            <br />
            <br />
            Click on an app below to read more about SimCrest developed add-on’s
            for Dynamics NAV.
          </p>
        </div>

        <div className="col-1 span-4 desktop-display">
          <img src={SimCrestAppGraphic} alt="SimCrest Apps" />
        </div>

        <div className="more-arrow-mobile mobile-display">
          <img src={IconArrow} />
        </div>

        <div className="col-5 span-10 simcrest-app-cards">
          <div className="simcrest-app-card">
            <div>
              <h4>Auto Email</h4>
              <p>
                Send advanced collection, shortpaid, non-paid, and statement
                notices.
              </p>
              <Button to="/simcrest-apps/auto-email">FIND OUT MORE</Button>
            </div>
            <div>
              <span>01</span>
            </div>
          </div>
          <img className="more-arrow delay-1 desktop-display" src={IconArrow} />
          <div className="simcrest-app-card">
            <div>
              <h4>Commission Management</h4>
              <p>Track and report commissions with little effort.</p>
              <Button to="/simcrest-apps/commissions">FIND OUT MORE</Button>
            </div>
            <div>
              <span>02</span>
            </div>
          </div>
          <img className="more-arrow delay-2 desktop-display" src={IconArrow} />
          <div className="simcrest-app-card">
            <div>
              <h4>E-Bank Reconciliation</h4>
              <p>
                Download bank statements and automatically reconcile your bank
                account.
              </p>
              <Button to="/simcrest-apps/ebank-reconciliation">
                FIND OUT MORE
              </Button>
            </div>
            <div>
              <span>03</span>
            </div>
          </div>
          <img className="more-arrow delay-3 desktop-display" src={IconArrow} />
          <div className="simcrest-app-card">
            <div>
              <h4>Time and Billing</h4>
              <p>
                Register time and bill customers with this easy-to-use app that
                also includes resource scheduling.
              </p>
              <Button to="/simcrest-apps/time-and-billing">
                FIND OUT MORE
              </Button>
            </div>
            <div>
              <span>04</span>
            </div>
          </div>
          <img className="more-arrow delay-4 desktop-display" src={IconArrow} />
          <div className="simcrest-app-card">
            <div>
              <h4>Cash Basis</h4>
              <p>
                SimCrest Cash Basis allows you to calculate your General Ledger
                using both Accrual and Cash Basis Accounting principles.
              </p>
              <Button to="/simcrest-apps/cash-basis">FIND OUT MORE</Button>
            </div>
            <div>
              <span>05</span>
            </div>
          </div>
          <img className="more-arrow delay-5 desktop-display" src={IconArrow} />
          <div className="simcrest-app-card">
            <div>
              <h4>Product Configurator</h4>
              <p>
                The Product Configurator helps to build custom sales and
                production orders by using easy to follow questionnaires.
              </p>
              <Button to="/simcrest-apps/product-configurator">
                FIND OUT MORE
              </Button>
            </div>
            <div>
              <span>06</span>
            </div>
          </div>
        </div>

        <div className="mobile-display simcrest-app-mobile-image">
          <img src={SimCrestAppGraphic} alt="SimCrest Apps" />
        </div>
      </section>

      <section className="section-services">
        <PageNumber
          id="services"
          className="col-3 span-11"
          number="04"
          text="SERVICES"
        />

        <div className="col-4 span-9">
          <IconButton
            className={`pointer ${service.id === 0 ? "active" : "nonactive"}`}
            onClick={() => setService(services[0])}
            icon={ICONS.analysisAndDesign}
            text="Analysis & Design"
          />
          <IconButton
            className={`pointer ${service.id === 1 ? "active" : "nonactive"}`}
            onClick={() => setService(services[1])}
            icon={ICONS.development}
            text="Development"
          />
          <IconButton
            className={`pointer ${service.id === 2 ? "active" : "nonactive"}`}
            onClick={() => setService(services[2])}
            icon={ICONS.projectManagement}
            text="Project Management"
          />
          <IconButton
            className={`pointer ${service.id === 3 ? "active" : "nonactive"}`}
            onClick={() => setService(services[3])}
            icon={ICONS.implementation}
            text="Implementation"
          />
          <IconButton
            className={`pointer ${service.id === 4 ? "active" : "nonactive"}`}
            onClick={() => setService(services[4])}
            icon={ICONS.trainingAndSupport}
            text="Training & Support"
          />
          <IconButton
            className={`pointer ${service.id === 5 ? "active" : "nonactive"}`}
            onClick={() => setService(services[5])}
            icon={ICONS.systemIntegration}
            text="System Integration"
          />
        </div>

        <div className="col-4 span-9">
          <div>
            <IconButton
              className="services-list-desktop"
              icon={service.IconButton}
            />
            <div className="services-list-desktop">
              <h4>{service.title}</h4>
              <p>{service.text}</p>
            </div>

            <div className="services-list-mobile">
              {services.map(service => {
                return (
                  <>
                    <IconButton icon={service.IconButton} />
                    <div>
                      <h4>{service.title}</h4>
                      <p>{service.text}</p>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          <h4></h4>
        </div>
      </section>

      <section className="section-contact">
        <h2>Simplify & Streamline</h2>
        <h1>Grow Your Business</h1>
        <Button to="/contactus">Contact Us</Button>
      </section>
    </Layout>
  )
}

const services = [
  {
    id: 0,
    IconButton: ICONS.analysisAndDesignFill,
    title: "Analysis & Design",
    text:
      "Our seasoned business consultants analyze our client’s needs and requirements and develop detailed design documents to establish scope.",
  },
  {
    id: 1,
    IconButton: ICONS.developmentFill,
    title: "Development",
    text:
      "SimCrest has some of the best in-house developers to create sophisticated integrated solutions using the latest technology.",
  },
  {
    id: 2,
    IconButton: ICONS.projectManagementFill,
    title: "Project Management",
    text:
      "Our project management professionals (PMP and Scrum certified) keep the projects on-time, on-budget, and within scope.",
  },
  {
    id: 3,
    IconButton: ICONS.implementationFill,
    title: "Implementation",
    text:
      "Our business consultants plan and execute the actual implementation including data conversions from the old system to the new one.",
  },
  {
    id: 4,
    IconButton: ICONS.trainingAndSupportFill,
    title: "Training and Support",
    text:
      "Our consultants will make sure to train everyone involved in the project how to use the software and ensure an easy transition from the old system.",
  },
  {
    id: 5,
    IconButton: ICONS.systemIntegrationFill,
    title: "System Integration",
    text:
      "If there is a need for integration of many systems like web, ERP, mobile, etc. our system architects will assist building the best integrated solution.",
  },
]
